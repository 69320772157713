import mixpanel from 'mixpanel-browser';

import { ENTITIES, ENTITY_VERSIONS } from '../entityContexts';
import { SESSION_REPLAY_DOMAIN } from '../constants';
import { getEntityContext } from './snowplowConstructContext';
import { getMixpanelReplayUrl } from '../Mixpanel/index';

const addEntityInterceptor = (payload) => {
  const entityContext = getEntityContext(
    ENTITIES.SESSION_REPLAY_CONTEXT,
    ENTITY_VERSIONS[ENTITIES.SESSION_REPLAY_CONTEXT],
    SESSION_REPLAY_DOMAIN
  );

  const snowplowContext = payload?.options?.snowplow?.contexts ?? [];
  return {
    ...payload,
    options: {
      ...payload.options,
      snowplow: {
        ...payload.options.snowplow,
        contexts: [...snowplowContext, entityContext],
      },
    },
  };
};

export const sessionReplayInterceptor = (payload) => {
  const sessionReplayCtx = {};

  const mixpanelReplayId = mixpanel.get_session_recording_properties()
    .$mp_replay_id;
  const mixpanelReplayUrl = getMixpanelReplayUrl();

  if (mixpanelReplayId) {
    sessionReplayCtx.mixpanel_replay_id = mixpanelReplayId;
  }
  if (mixpanelReplayUrl) {
    sessionReplayCtx.mixpanel_replay_url = mixpanelReplayUrl;
  }

  const contexts = payload.contexts ?? [];
  const hasSessionContext = contexts.some(
    (ctx) => ctx.name === 'session_replay_context'
  );

  payload.context = { ...payload.context, ...sessionReplayCtx };

  if (!hasSessionContext && Object.keys(sessionReplayCtx).length > 0) {
    const entity = addEntityInterceptor(payload);
    return entity;
  }

  return payload;
};
