/* eslint-disable no-console */
import { v4 as uuid } from 'uuid';
import { createInstance, eventDispatcher } from '@optimizely/react-sdk';
import {
  MPARTICLE_KEY as mparticleId,
  GTM_KEY as gtmId,
  MPARTICLE_ENABLED,
  GTM_ENABLED,
  ENVIRONMENT,
  ANALYTICS_ENVIRONMENT,
  SNOWPLOW_ENABLED,
  SNOWPLOW_INGEST_ENDPOINT,
  OPTIMIZELY_ENABLED,
  OPTIMIZELY_SDK_KEY,
  ONETRUST_ENABLED,
  MIXPANEL_ENABLED,
  MIXPANEL_PROJECT_TOKEN,
} from 'config';
import { trackingClient } from '@utilitywarehouse/partner-tracking-core';
import { devtoolsAdapter } from '@utilitywarehouse/partner-tracking-adapter-devtools';
import { mparticleAdapter } from '@utilitywarehouse/partner-tracking-adapter-mparticle-web';
import { gtagsAdapter } from '@utilitywarehouse/partner-tracking-adapter-gtm-web';
import { snowplowAdapter } from '@utilitywarehouse/partner-tracking-adapter-snowplow-web';
import { EnhancedConsentPlugin } from '@snowplow/browser-plugin-enhanced-consent';
import { contextInterceptor } from './AnalyticsProvider/contextInterceptor';
import { addEntityInterceptor } from './AnalyticsProvider/snowplowConstructContext';
import { sessionReplayInterceptor } from './AnalyticsProvider/sessionReplayInterceptor';
import { mixpanelAdapter } from './Mixpanel';
import ANALYTICS_PAGES, { VERSIONS, EVENT_TYPE } from './constants';

let client;
let optimizely;

class Analytics {
  static setGoogleConsentDefaults() {
    const defaultConsentState = ONETRUST_ENABLED ? 'denied' : 'granted';
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('consent', 'default', {
      ad_storage: defaultConsentState,
      analytics_storage: defaultConsentState,
      functionality_storage: defaultConsentState,
      personalization_storage: defaultConsentState,
      security_storage: defaultConsentState,
      ad_user_data: defaultConsentState,
      ad_personalization: defaultConsentState,
      wait_for_update: 500,
    });
  }
  static getPageName(pathname) {
    return ANALYTICS_PAGES[pathname];
  }

  constructor() {
    const adapters = [devtoolsAdapter()];
    const reduxMiddlewares = [];

    if (MPARTICLE_ENABLED) {
      adapters.push(
        mparticleAdapter({
          key: mparticleId,
          developmentMode: ANALYTICS_ENVIRONMENT === 'development',
          identityUserDataMapper: (data = {}) => ({
            email: data.email || null,
          }),
        })
      );
    }

    if (GTM_ENABLED) {
      Analytics.setGoogleConsentDefaults();
      adapters.push(
        gtagsAdapter({
          key: gtmId,
          scriptTagAttributes: { 'data-ot-ignore': '' },
        })
      );
    }

    if (SNOWPLOW_ENABLED) {
      const snowplowPlugins = [EnhancedConsentPlugin()];

      adapters.push(
        snowplowAdapter({
          collector: SNOWPLOW_INGEST_ENDPOINT,
          appId: 'jtc',
          options: {
            debug: ENVIRONMENT === 'development',
            trackPageViewContext: false,
            hasConsent: !ONETRUST_ENABLED,
          },
          plugins: snowplowPlugins,
        })
      );
    }

    if (OPTIMIZELY_ENABLED) {
      optimizely = createInstance({
        sdkKey: OPTIMIZELY_SDK_KEY,
        eventDispatcher,
      });
    }

    // Snowplow schema version interceptor. Adds the schema version
    // depending on the event name, or eventType in the case of pages
    const addVersionInterceptor = (payload) => {
      // page tracking uses the page name as the event, so
      // we must use the eventType property to determine what
      // schema version to use
      const version =
        payload?.context?.event_type === EVENT_TYPE.PAGE_VIEW
          ? VERSIONS[EVENT_TYPE.PAGE_VIEW]
          : VERSIONS[payload.event];

      return {
        ...payload,
        options: {
          ...payload.options,
          snowplow: {
            ...payload.options.snowplow,
            version: payload.options.snowplow.version ?? version,
          },
        },
      };
    };

    this.client = trackingClient({
      adapters,
      interceptors: [
        addEntityInterceptor,
        contextInterceptor,
        addVersionInterceptor,
      ],
    });
    this.anonIdKey = 'jtc_analytics_anon_id';
    this.reduxMiddlewares = reduxMiddlewares;

    if (MIXPANEL_ENABLED) {
      this.client.addAdapter(
        mixpanelAdapter({
          token: MIXPANEL_PROJECT_TOKEN,
          debug: ENVIRONMENT === 'development',
        })
      );

      this.client.addInterceptor(sessionReplayInterceptor);
    }
  }

  getAnonId() {
    if (window.sessionStorage) {
      return window.sessionStorage.getItem(this.anonIdKey);
    }
  }

  setAnonId(id) {
    if (!id) {
      throw new TypeError('id value is required');
    }

    if (window.sessionStorage) {
      window.sessionStorage.setItem(this.anonIdKey, id);
    }
  }

  removeAnonId() {
    if (window.sessionStorage) {
      window.sessionStorage.removeItem(this.anonIdKey);
    }
  }

  newSession(userId, params = {}) {
    try {
      this.client.resetSession();
      this.removeAnonId();
      this.identify(userId, params);
    } catch (e) {
      console.warn(e);
    }
  }

  page(pathname, addressObj = {}) {
    if (typeof pathname !== 'string') {
      console.error(`pathname must be a string, received ${typeof pathname}`);
    }

    this.client.page(pathname, addressObj);
  }

  identify(id, traits) {
    let analyticsId = id || this.getAnonId();
    if (!analyticsId) {
      analyticsId = uuid();
      this.setAnonId(analyticsId);
    }

    try {
      this.client.startSession(analyticsId, traits);
    } catch (e) {
      console.warn(e);
    }
  }

  track(event, properties, options) {
    try {
      this.client.track(event, properties, options);
    } catch (e) {
      console.warn(e);
    }
  }
}

function initAnalytics() {
  client = new Analytics();
  return client;
}

function getClient() {
  return client;
}

function getOptimizelyClient() {
  return optimizely;
}

export { initAnalytics, getClient, Analytics, getOptimizelyClient };
export { createProduct } from './ecommerce';
