import { EVENTS, OPTLY_DOMAIN } from './constants';

export const ENTITIES = {
  CORE_SERVICES: 'jtc_core_services',
  SERVICE_DROPDOWN: 'jtc_service_dropdown',
  CHECKOUT: 'jtc_checkout',
  OPTLY_DECIDE_DATA: 'optimizely_decision_called_metadata',
  OPTLY_EVENT_DATA: 'optimizely_event_tracked_metadata',
  SESSION_REPLAY_CONTEXT: 'session_replay_context',
};

export const ENTITY_VERSIONS = {
  [ENTITIES.CORE_SERVICES]: '1-0-5',
  [ENTITIES.SERVICE_DROPDOWN]: '1-0-0',
  [ENTITIES.CHECKOUT]: '1-0-5',
  [ENTITIES.OPTLY_DECIDE_DATA]: '1-0-0',
  [ENTITIES.OPTLY_EVENT_DATA]: '1-0-0',
  [ENTITIES.SESSION_REPLAY_CONTEXT]: '1-0-0',
};

export const ENTITY_PROPERTIES = {
  [ENTITIES.CORE_SERVICES]: [
    'energy',
    'broadband',
    'mobile',
    'insurance',
    'energy_price',
    'broadband_price',
    'mobile_price',
    'insurance_price',
    'multiple_sims_selected',
    'number_of_mobile_services',
    'number_of_core_services',
  ],
  [ENTITIES.SERVICE_DROPDOWN]: [
    'service_name',
    'core_service_name',
    'is_dropdown_open',
  ],
  [ENTITIES.CHECKOUT]: [
    'actual_monthly_cost',
    'joint_account_holder_added',
    'cbc_holder_added',
    'recent_move',
    'referrer_type',
    'referrer_id',
    'existing_broadband_line',
    'existing_broadband_provider_not_listed',
  ],
  [ENTITIES.OPTLY_DECIDE_DATA]: [
    'experiment_key',
    'variation_key',
    'enabled',
    'user_id',
  ],
  [ENTITIES.OPTLY_EVENT_DATA]: ['event_key', 'user_id', 'revenue', 'value'],
  [ENTITIES.SESSION_REPLAY_CONTEXT]: [
    'mixpanel_replay_id',
    'mixpanel_replay_url',
    'openreplay_replay_id',
    'openreplay_replay_url',
  ],
};

export const EVENT_ENTITY_MAP = {
  [EVENTS.APPLICATION_SERVICES_SELECTED]: [
    {
      entityName: ENTITIES.CORE_SERVICES,
      version: ENTITY_VERSIONS[ENTITIES.CORE_SERVICES],
    },
  ],
  [EVENTS.APPLICATION_SERVICES_PRESENTED]: [
    {
      entityName: ENTITIES.CORE_SERVICES,
      version: ENTITY_VERSIONS[ENTITIES.CORE_SERVICES],
    },
  ],
  [EVENTS.APPLICATION_SUMMARY_PRESENTED]: [
    {
      entityName: ENTITIES.CORE_SERVICES,
      version: ENTITY_VERSIONS[ENTITIES.CORE_SERVICES],
    },
  ],
  [EVENTS.APPLICATION_SUBMITTED]: [
    {
      entityName: ENTITIES.CORE_SERVICES,
      version: ENTITY_VERSIONS[ENTITIES.CORE_SERVICES],
    },
    {
      entityName: ENTITIES.CHECKOUT,
      version: ENTITY_VERSIONS[ENTITIES.CHECKOUT],
    },
  ],
  [EVENTS.YOUR_DETAILS_REFERRER_ADDED]: [
    {
      entityName: ENTITIES.CHECKOUT,
      version: ENTITY_VERSIONS[ENTITIES.CHECKOUT],
    },
  ],
  [EVENTS.APPLICATION_COMPLETED]: [
    {
      entityName: ENTITIES.CORE_SERVICES,
      version: ENTITY_VERSIONS[ENTITIES.CORE_SERVICES],
    },
  ],
  [EVENTS.BUNDLES_SERVICE_SELECTED]: [
    {
      entityName: ENTITIES.CORE_SERVICES,
      version: ENTITY_VERSIONS[ENTITIES.CORE_SERVICES],
    },
  ],
  [EVENTS.BUNDLES_SERVICE_REMOVED]: [
    {
      entityName: ENTITIES.CORE_SERVICES,
      version: ENTITY_VERSIONS[ENTITIES.CORE_SERVICES],
    },
  ],
  [EVENTS.SUMMARY_PRODUCT_REMOVE]: [
    {
      entityName: ENTITIES.CORE_SERVICES,
      version: ENTITY_VERSIONS[ENTITIES.CORE_SERVICES],
    },
  ],
  [EVENTS.SUMMARY_PRODUCT_ADD]: [
    {
      entityName: ENTITIES.CORE_SERVICES,
      version: ENTITY_VERSIONS[ENTITIES.CORE_SERVICES],
    },
  ],
  [EVENTS.SUMMARY_PRODUCT_DETAIL_CLICK]: [
    {
      entityName: ENTITIES.SERVICE_DROPDOWN,
      version: ENTITY_VERSIONS[ENTITIES.SERVICE_DROPDOWN],
    },
  ],
  [EVENTS.OPTLY_DECIDE_TRACK]: [
    {
      entityName: ENTITIES.OPTLY_DECIDE_DATA,
      version: ENTITY_VERSIONS[ENTITIES.OPTLY_DECIDE_DATA],
      domain: OPTLY_DOMAIN,
    },
  ],
  [EVENTS.OPTLY_EVENT_TRACK]: [
    {
      entityName: ENTITIES.OPTLY_EVENT_DATA,
      version: ENTITY_VERSIONS[ENTITIES.OPTLY_EVENT_DATA],
      domain: OPTLY_DOMAIN,
    },
  ],
};
