/*
 * Function to extract the Snowplow Domain User Information from the first-party cookie set by the Snowplow JavaScript Tracker
 * https://docs.snowplow.io/docs/sources/trackers/javascript-trackers/web-tracker/cookies-and-local-storage/getting-cookie-values/
 *
 * @param string cookieName (optional) The value used for "cookieName" in the tracker constructor argmap
 * (leave blank if you did not set a custom cookie name)
 *
 * @return string or bool The ID string if the cookie exists or false if the cookie has not been set yet
 */
function getSnowplowDuid(cookieName = '_sp_') {
  const matcher = new RegExp(cookieName + 'id\\.[a-f0-9]+=([^;]+);?');
  const match = document.cookie.match(matcher);
  if (!match) return false;
  const split = match[1].split('.');
  if (match && match[1]) {
    return {
      domainUserId: split[0],
      domainSessionIdx: split[2],
      domainSessionId: split[5],
    };
  } else {
    return false;
  }
}

export { getSnowplowDuid };
