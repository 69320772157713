import forEach from 'lodash/forEach';
import isString from 'lodash/isString';
import * as SentryLib from '@sentry/react';

import { APP_URL, SALES_AUTH_URL } from 'config';
import { STORE_NAME as FeatureFlagsStore } from 'redux/modules/FeatureFlags/constants';
import { STORE_NAME as AppStore } from 'redux/modules/App/constants';
import { detectBrowserSupport } from 'redux/utils/detectBrowserSupport';

import { SEVERITY, REASON } from './errors';

const Sentry = {
  init: (DSN, ENV, RELEASE) => {
    if (DSN !== '') {
      SentryLib.init({
        dsn: DSN,
        environment: ENV,
        release: RELEASE,
        allowUrls: [APP_URL, SALES_AUTH_URL],
        normalizeDepth: 4,
        integrations: [SentryLib.browserTracingIntegration()],
        tracesSampleRate: 0.1,
      });
    }
  },

  log: (errorObj, errorDescription = {}) => {
    const errorInstance =
      errorObj instanceof Error
        ? errorObj
        : new Error(isString(errorObj) ? errorObj : 'Unknown error');

    SentryLib.withScope((scope) => {
      const isBrowserSupported = detectBrowserSupport();

      scope.setLevel(
        errorDescription.severity ||
          (isBrowserSupported ? SEVERITY.ERROR : SEVERITY.WARNING)
      );

      // fingerprinting
      if (errorDescription.reason)
        scope.setFingerprint([`code:${errorDescription.reason}`]);

      // tags
      forEach(errorDescription.tags, (value, key) => scope.setTag(key, value));
      scope.setTag('code', errorDescription.reason || REASON.UNSPECIFIED);
      scope.setTag('supportedBrowser', isBrowserSupported);
      if (errorDescription?.extra?.info) {
        scope.setTag('extraInfo', errorDescription?.extra?.info);
      }
      // extra
      forEach(errorDescription.extra, (value, key) =>
        scope.setExtra(key, value)
      );
      // context
      forEach(errorDescription.context, (value, key) =>
        scope.setTag(key, value)
      );

      SentryLib.captureException(errorInstance);
    });
  },
};

export const sentryReduxEnhancer = SentryLib.createReduxEnhancer({
  stateTransformer: (state) => {
    // transform state to remove sensitive information
    const transformedState = {
      [FeatureFlagsStore]: state.getIn([FeatureFlagsStore]).toJS(),
      [AppStore]: state.getIn([AppStore, 'application']).toJS(),
    };

    return transformedState;
  },
  actionTransformer: () => {
    // do not send any actions to Sentry
    return null;
  },
});

export default Sentry;
