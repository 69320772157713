export const SEVERITY = {
  DEBUG: 'debug',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
  FATAL: 'fatal',
};

export const REASON = {
  REQUEST_FAILED: 'REQUEST_FAILED',
  LOGIC_ERROR: 'LOGIC_ERROR',
  UNSPECIFIED: 'UNSPECIFIED',
};

// extra.info is used for issue fingerprinting, it's also added as a tag
const ERRORS = {
  ACCEPT_HOME_COVER_QUOTE: {
    severity: SEVERITY.WARNING,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to update the Home Cover',
    },
  },
  PARTNER_LOGIN: {
    severity: SEVERITY.WARNING,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Error during partner login',
    },
  },
  SUPPORTING_PARTNER_LOGIN: {
    severity: SEVERITY.WARNING,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Error during supporting partner login',
    },
  },
  SALES_LOGIN: {
    severity: SEVERITY.WARNING,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Error during sales login',
    },
  },
  REMOTE_SESSION_RUN: {
    severity: SEVERITY.ERROR,
    reason: REASON.UNSPECIFIED,
    extra: {
      info: 'Error during running the remote session',
    },
  },
  GET_FEATURE_FLAGS: {
    severity: SEVERITY.WARNING,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to get the feature flags',
    },
  },
  GET_APP_DETAILS: {
    severity: SEVERITY.WARNING,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to get the application details',
    },
  },
  GET_APP_COMPLETE: {
    severity: SEVERITY.WARNING,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Order failed',
    },
  },
  GET_APP_SUMMARY: {
    severity: SEVERITY.WARNING,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to get the application summary',
    },
  },
  GET_APP_RISK: {
    severity: SEVERITY.WARNING,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to get the risk summary',
    },
  },
  GET_CREDIT_RISK: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to get the risk summary',
    },
  },
  GET_CASHBACK_REVIEWS_REQUEST: {
    severity: SEVERITY.WARNING,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to get the cashback trustpilot reviews',
    },
  },
  GET_CASHBACK_RETAILERS_REQUEST: {
    severity: SEVERITY.WARNING,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to get the cashback retailers',
    },
  },
  GET_NEW_LINE_APP: {
    severity: SEVERITY.WARNING,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to get the new line appointments',
    },
  },
  GET_NEW_LINE_APP_COST: {
    severity: SEVERITY.WARNING,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to get the new line appointment cost',
    },
  },
  GET_LINE_CHECK_STATUS: {
    severity: SEVERITY.WARNING,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to get line check status',
    },
  },
  GET_MOBILE_TARIFFS: {
    severity: SEVERITY.WARNING,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to get the mobile tariffs',
    },
  },
  GET_MOBILE_HANDSETS: {
    severity: SEVERITY.WARNING,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to get the mobile handsets',
    },
  },
  GET_HOMEPHONE_FEATURES: {
    severity: SEVERITY.WARNING,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to get the homephone features',
    },
  },
  GET_HOMEPHONE_ADVANCED_FEATURES: {
    severity: SEVERITY.WARNING,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to get the homephone advanced features',
    },
  },
  GET_HOMEPHONE_HIGHLIGHTED_FEATURES: {
    severity: SEVERITY.WARNING,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to get the homephone advanced highlighted features',
    },
  },
  GET_HOMEPHONE_LINE_RENTAL: {
    severity: SEVERITY.WARNING,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to get the homephone line rental',
    },
  },
  GET_DAFFODIL_APPTS: {
    severity: SEVERITY.WARNING,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to get the daffodil appointments dates',
    },
  },
  GET_DAFFODIL_RANGE: {
    severity: SEVERITY.WARNING,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to get the daffodil bulb range',
    },
  },
  GET_BUNDLES: {
    severity: SEVERITY.WARNING,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to get the bundles',
    },
  },
  GET_CONTENT_PAGE_OR_ASSET: {
    severity: SEVERITY.WARNING,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to get the Content page or asset',
    },
  },
  GET_ADDRESS_LIST: {
    severity: SEVERITY.WARNING,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to get the list of addresses from a post code',
    },
  },
  GET_ADDRESS_DETAILS: {
    severity: SEVERITY.WARNING,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to get the address details',
    },
  },
  GET_BROADBAND_TARIFFS: {
    severity: SEVERITY.WARNING,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to get the broadband tariffs',
    },
  },
  GET_BROADBAND_ROUTERS: {
    severity: SEVERITY.WARNING,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to get the broadband routers',
    },
  },
  GET_GOLD_BENEFITS_ELIGIBILITY: {
    severity: SEVERITY.WARNING,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to get the gold benefits eligibility',
    },
  },
  GET_BOILER_COVER_QUOTE: {
    severity: SEVERITY.WARNING,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to get the Boiler Cover quote',
    },
  },
  GET_HOME_COVER_QUOTE: {
    severity: SEVERITY.WARNING,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to get the Home Cover quote',
    },
  },
  UPDATE_APP_PROPERTY_METER: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to update the property meters info',
    },
  },
  UPDATE_BROADBAND_DETAILS: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to update the broadband details',
    },
  },
  UPDATE_BILL_PROTECTOR: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to update the Bill Protector',
    },
  },
  UPDATE_BOILER_COVER: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to update the Boiler Cover',
    },
  },
  UPDATE_BOILER_COVER_QUOTE: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to update the Boiler Cover quote',
    },
  },
  UPDATE_HOME_COVER_QUOTE: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to update the Home Cover quote',
    },
  },
  UPDATE_DAFFODIL_APPTS: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to update the daffodil appointments',
    },
  },
  UPDATE_ENERGY_QUOTE: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to update the energy quotes',
    },
  },
  UPDATE_ENERGY_ACCEPT_QUOTE: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to accept the energy quotes',
    },
  },
  UPDATE_ENTRY_FORM: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to update the entry form',
    },
  },
  UPDATE_ENTRY_FORM_ADDRESS: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to update the entry form address',
    },
  },
  UPDATE_GOLD_BENEFITS: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to update the gold benefits',
    },
  },
  UPDATE_HOMEPHONE: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to update the homephone options',
    },
  },
  UPDATE_MOBILE_PRODUCTS: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to update the mobile products',
    },
  },
  UPDATE_MOBILE_PRODUCT_REMOVAL: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to remove the selected mobile handset',
    },
  },
  UPDATE_MOBILE_CODE_VALIDATION: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to validate the mobile code',
    },
  },
  UPDATE_MOBILE_CODES_VALIDATION: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to validate the mobile codes',
    },
  },
  UPDATE_NEW_LINE_APP_DATE: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to update the new line appointment date',
    },
  },
  UPDATE_APP_PRODUCT_REMOVAL: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to remove the product',
    },
  },
  UPDATE_SERVICE_SELECTION_REMOVAL: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to remove the service selection',
    },
  },
  UPDATE_ABOUT_YOU_BILLING_ADDRESS: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to update the billing address',
    },
  },
  UPDATE_ABOUT_YOU_APP_DETAILS: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to update the appointment details',
    },
  },
  UPDATE_ABOUT_YOU_NAME_EMAIL: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to update the name or email',
    },
  },
  UPDATE_ABOUT_YOU_NAME_MOCK: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to send mocked account details for credit check',
    },
  },
  GET_ABOUT_YOU_INITIAL_REQUEST: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to get application summary on form initialization',
    },
  },
  UPDATE_ABOUT_YOU_CLUBHOUSE: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to create the clubhouse account',
    },
  },
  UPDATE_ABOUT_YOU_CASHBACK: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to update the cashback service details',
    },
  },
  UPDATE_ABOUT_YOU_LANDLORD: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to update the landlord',
    },
  },
  UPDATE_ABOUT_YOU_REFERRAL: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to update the referral',
    },
  },
  UPDATE_PARTNER_APP: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to create the partner application',
    },
  },
  UPDATE_PARTNER_REMOTE_APP: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to create the remote application',
    },
  },
  UPDATE_ORGANIC_APP: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to create the organic application',
    },
  },
  UPDATE_SALES_APP: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to create the sales application',
    },
  },
  UPDATE_REMOTE_SUPPORTING: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to update the application with the remote supporting id',
    },
  },
  UPDATE_PAYMENT_CARD: {
    severity: SEVERITY.WARNING,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to save the payment card details',
    },
  },
  UPDATE_CNP_PAYMENT: {
    severity: SEVERITY.WARNING,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to save the payment card details (CNP)',
    },
  },
  TRACK_ENTRY_FORM: {
    severity: SEVERITY.WARNING,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to send the entry form analytics',
    },
  },
  SALES_LEAD_NOT_FOUND: {
    severity: SEVERITY.WARNING,
    reason: REASON.UNSPECIFIED,
    extra: {
      info: 'Lead not found. Possibly not a valid sales lead.',
    },
  },
  SALES_LEAD_FAILURE: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Error requesting sales lead.',
    },
  },
  UPDATE_REQUESTED_SERVICES: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to update requested-services',
    },
  },
  GET_PROPOSITION: {
    severity: SEVERITY.WARNING,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to get the proposition service prices.',
    },
  },
  GET_PROPOSITION_RULES: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to get the proposition service rules',
    },
  },
  JOURNEY_TRANSITION: {
    severity: SEVERITY.FATAL,
    reason: REASON.LOGIC_ERROR,
    extra: {
      info: 'Journey machine failed to get next path',
    },
  },
  EECC_REQUEST_EMAIL: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to request telecom contract email',
    },
  },
  EECC_REQUEST_DOWNLOAD: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to request telecom contract URL',
    },
  },
  APP_SUBMIT_ANALYTICS: {
    severity: SEVERITY.ERROR,
    reason: REASON.LOGIC_ERROR,
    extra: {
      info: 'Error creating analytics products',
    },
  },
  UPDATE_YOUR_DETAILS_ADDRESS: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to submit "Your Details: Your Address" form',
    },
  },
  UPDATE_YOUR_BROADBAND_SWITCH: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to submit "Your Details: Your Broadband Switch" form',
    },
  },
  YOUR_DETAILS_SUBMIT_CBC: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to submit Cashback holders',
    },
  },
  GET_BUNDLE_RESTRICTIONS: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to fetch bundle restrictions on bundle page',
    },
  },
  GET_BANK_HOLIDAYS: {
    severity: SEVERITY.WARNING,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to fetch bank holidays',
    },
  },
  CLEAR_COOKIES: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to clear cookies when withdrawing tracking consent',
    },
  },
  LEAD_CAPTURE: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to capture customer RAF lead details',
    },
  },
  CREATE_LEAD: {
    severity: SEVERITY.ERROR,
    reason: REASON.REQUEST_FAILED,
    extra: {
      info: 'Failed to create customer lead details',
    },
  },
};

export default ERRORS;
