import * as Sentry from '@sentry/react';
import { TOPIC } from '@utilitywarehouse/partner-tracking-core';
import mixpanel from 'mixpanel-browser';
import { getSnowplowDuid } from '../domainUserId';

let replayUrl = null;

export const getMixpanelReplayUrl = () => replayUrl;

const setupMixpanelAdapter = ({ token, debug = false }) => {
  if (!token) {
    return;
  }

  //   https://docs.mixpanel.com/docs/tracking-methods/sdks/javascript#init-options
  mixpanel.init(token, {
    api_host: 'https://api-eu.mixpanel.com',
    record_sessions_percent: 100,
    debug,
    persistence: 'localStorage',
    record_mask_text_selector: '',
  });

  const snowplowDuidData = getSnowplowDuid();
  if (snowplowDuidData) {
    const { domainUserId } = snowplowDuidData;
    mixpanel.identify(domainUserId);
  }

  const replayId = mixpanel.get_session_recording_properties()?.$mp_replay_id;

  const distinctId = mixpanel?.get_distinct_id();

  if (replayId && distinctId && token) {
    replayUrl = `https://mixpanel.com/projects/replay-redirect?replay_id=${replayId}&distinct_id=${encodeURIComponent(
      distinctId
    )}&token=${token}`;
  }

  // No official integration but can be pushed as tags - https://docs.sentry.io/platforms/javascript/enriching-events/tags/
  if (replayId) Sentry.setTag('mixpanelReplay.id', replayId);
  if (replayUrl) Sentry.setTag('mixpanelReplayURL', replayUrl);

  return function mixpanelAdapter(tracking) {
    const initialSession = tracking.getSession();
    if (initialSession?.user !== undefined) {
      identify(initialSession);
    }

    const unsubStart = tracking.subscribe(TOPIC.SESSION_START, identify);
    const unsubReset = tracking.subscribe(TOPIC.SESSION_RESET, reset);

    const identify = (session) => {
      const userId = session?.user?.id;

      if (userId) {
        mixpanel.identify(userId);
      }
    };

    const reset = () => {
      mixpanel.reset();
    };

    return () => {
      unsubStart();
      unsubReset();
    };
  };
};

export { setupMixpanelAdapter as mixpanelAdapter };
